function frameClassName(propsHeader, value) {
  if (propsHeader === undefined) return null;
  if (propsHeader.children !== "馬番") return null;
  switch (value) {
    case 1:
      return "frame-white";
    case 2:
      return "frame-black";
    case 3:
      return "frame-red";
    case 4:
      return "frame-blue";
    case 5:
      return "frame-yellow";
    case 6:
      return "frame-green";
    case 7:
      return "frame-orange";
    case 8:
      return "frame-pink";
    default:
      return null;
  }
}

export default frameClassName;
