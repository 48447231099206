import axios from "axios";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import Nend from "../helpers/Nend";
import pastClassName from "../helpers/PastClassName";

const url = process.env.REACT_APP_BACKEND_ENDPOINT;
const username = process.env.REACT_APP_BACKEND_USERNAME;
const password = process.env.REACT_APP_BACKEND_PASSWORD;

function HistoryRaces() {
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [places, setPlaces] = useState([]);
  const [races, setRaces] = useState([]);

  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedPlace, setSelectedPlace] = useState(undefined);

  // Horsesから渡されて上書きされるもの
  const location = useLocation();
  const updateDate = useRef(undefined);
  const updatePlace = useRef(undefined);
  useEffect(() => {
    if (location.state !== null) {
      if (location.state.updateDate !== undefined) {
        updateDate.current = location.state.updateDate;
      }
      if (location.state.updatePlace !== undefined) {
        updatePlace.current = location.state.updatePlace;
      }
    } else {
      updateDate.current = undefined;
      updatePlace.current = undefined;
    }
  }, []);
  useEffect(() => {
    if (updateDate.current !== undefined) {
      setSelectedDate(updateDate.current);
    }
  }, [updateDate.current]);
  useEffect(() => {
    if (updatePlace.current !== undefined) {
      setSelectedPlace(updatePlace.current);
    }
  }, [updatePlace.current]);

  const handleChangeSelectedDate = (date) => {
    setSelectedDate(date);
  };

  const handleChangeSelectedPlace = (place) => {
    setSelectedPlace(place);
  };

  useEffect(() => {
    async function getSchedule() {
      try {
        await axios
          .get(`${url}/schedule_history`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            auth: {
              username: username,
              password: password,
            },
          })
          .then((res) => {
            const resSchedule = res.data.schedule;
            const tmp = resSchedule.map((tmp1) => tmp1.race_date);
            setSchedule([...schedule, ...tmp]);

            // 最後の日付がデフォルトで選択されているようにする
            const initSchedule = tmp.slice(-1)[0];
            setSelectedDate(initSchedule);
          });
      } catch (e) {
        // TODO: 開催日一覧が取得できなかったときの対応
      }
    }

    getSchedule();
  }, []); // 初回レンダリング時のみ実行

  useEffect(() => {
    async function getPlaces() {
      if (selectedDate === undefined) return; // dateが選択される前ではリクエストしない
      setLoading(true);
      try {
        await axios
          .get(`${url}/places?race_date=${selectedDate}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            auth: {
              username: username,
              password: password,
            },
          })
          .then((res) => {
            const places = res.data.places;
            setPlaces(places);

            // 取得できたら先頭のplaceで更新
            setSelectedPlace(places[0].name);
          });
      } catch (e) {
        console.log(e);
        setPlaces([{ name: "取得中..." }]);
      }
    }

    getPlaces();
  }, [selectedDate]); // 日付選択が変更されたときのみ実行

  useEffect(() => {
    async function getRaces() {
      // dateやplaceが取得できるまでは実行しない
      if (selectedDate === undefined || selectedPlace === undefined) return;
      setLoading(true);
      try {
        await axios
          .get(
            `${url}/races?race_date=${selectedDate}&place=${selectedPlace}`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
              },
              auth: {
                username: username,
                password: password,
              },
            }
          )
          .then((res) => {
            const races = res.data.races;
            setRaces(races);
            setLoading(false);
          });
      } catch (e) {
        console.log(e);
        setRaces([{ race_name: "レース一覧取得失敗" }]);
      }
    }

    getRaces();
  }, [selectedDate + selectedPlace]); // 日付もしくは競馬場が変更されたときのみ実行

  return (
    <div className="content">
      <div className="dates">
        <div className="radiobox">
          {schedule.map((date) => (
            <div className="date">
              <input
                id={date}
                className="radio_button_date"
                type="radio"
                value={date}
                onChange={() => {
                  handleChangeSelectedDate(date);
                }}
                checked={date === selectedDate}
              />
              <label for={date}>{date.slice(5).replace("-", "/")}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="places">
        <div className="radiobox">
          {places.map((place) => (
            <div className="place">
              <input
                id={place.name}
                className="radio_button_place"
                type="radio"
                value={place.name}
                onChange={() => {
                  handleChangeSelectedPlace(place.name);
                }}
                checked={place.name === selectedPlace}
              />
              <label for={place.name}>{place.name}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="nend-container">
        <Nend
          params={{
            media: 71818,
            site: 367030,
            spot: 1084545,
            type: 1,
            oriented: 1,
          }}
        />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="races">
          {races.map((race) => (
            <li className="race">
              <Link
                className={classNames(
                  "race-link",
                  pastClassName(selectedDate, race.start_datetime)
                )}
                to="/race/horses_history"
                state={{
                  raceId: race.race_id,
                  dateList: schedule,
                  date: selectedDate,
                  places: places,
                  place: selectedPlace,
                  raceCount: races.length,
                }}
              >
                {race.ordinal}R
              </Link>
              <div className="race-container">
                <div className="race-description">
                  <Link
                    className="race-link-sub"
                    to="/race/horses_history"
                    state={{
                      raceId: race.race_id,
                      dateList: schedule,
                      date: selectedDate,
                      places: places,
                      place: selectedPlace,
                      raceCount: races.length,
                    }}
                  >
                    <p className="race-name">{race.race_name} </p>
                  </Link>
                  <p className="race-detail">
                    {race.start_datetime} {race.race_type}
                    {race.course_length}m {race.head_count}頭
                  </p>
                </div>
                {race.is_estimated ? (
                  <p className="existing-ai-index">AI</p>
                ) : (
                  ""
                )}
                {race.is_result ? <p className="existing-result">着順</p> : ""}
              </div>
            </li>
          ))}
        </div>
      )}
    </div>
  );
}

export default HistoryRaces;
