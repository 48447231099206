function indexClassName(headerName, value) {
  if (headerName === undefined || typeof headerName !== "string") return null;
  if (headerName.indexOf("指数") === -1) return null;

  if (50 <= value && value < 60) {
    return "index-50-60";
  } else if (60 <= value && value < 70) {
    return "index-60-70";
  } else if (70 <= value && value < 80) {
    return "index-70-80";
  } else if (80 <= value) {
    return "index-80-";
  } else {
    return null;
  }
}

export default indexClassName;
