import InfoWindow from "../components/InfoWindow";
import Usecase from "../components/Usecase";

function Home() {
  return (
    <div>
      <InfoWindow />
      <Usecase />
    </div>
  );
}

export default Home;
