function popularityClassName(propsHeader, value) {
  if (propsHeader === undefined) return null;
  if (propsHeader.children !== "人気") return null;
  if (1 <= value && value <= 3) {
    return "popularity-bold";
  } else {
    return null;
  }
}

export default popularityClassName;
