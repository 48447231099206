import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import Loading from "../components/Loading";
import "./HorseHistory.css";

const url = process.env.REACT_APP_BACKEND_ENDPOINT;
const username = process.env.REACT_APP_BACKEND_USERNAME;
const password = process.env.REACT_APP_BACKEND_PASSWORD;

const HorseHistory = () => {
  const { horse_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [horseDetail, setHorseDetail] = useState(null);

  useEffect(() => {
    async function getHistory() {
      setLoading(true);
      try {
        const response = await axios.get(`${url}/horses/${horse_id}/history`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
          },
          auth: {
            username: username,
            password: password,
          },
        });
        // resultがnullでないものを抽出する
        setHorseDetail({
          ...response.data,
          past_races: response.data.past_races.filter(
            (race) => race.horse_details.result !== null
          ),
        });
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }

    getHistory();
  }, [horse_id]);

  const data = useMemo(
    () => (horseDetail ? horseDetail.past_races : []),
    [horseDetail]
  );

  const columns = useMemo(
    () => [
      {
        Header: "出走日付",
        accessor: "race_date",
      },
      {
        Header: "レース名",
        accessor: "race_name",
      },
      {
        Header: "人気",
        accessor: "horse_details.popularity",
      },
      {
        Header: "オッズ",
        accessor: "horse_details.odds",
      },
      {
        Header: "AI指数",
        accessor: (race) => (race.horse_details.ai_index * 100).toFixed(1),
      },
      {
        Header: "着順",
        accessor: "horse_details.result",
      },
      {
        Header: "R",
        accessor: "ordinal",
      },
      {
        Header: "出走数",
        accessor: "head_count",
      },
      {
        Header: "馬番/枠",
        accessor: (race) =>
          `${race.horse_details.order} / ${race.horse_details.frame}`,
      },
      {
        Header: "レースタイプ",
        accessor: (race) =>
          `${race.course.race_type}, ${race.course.course_length}m`,
      },
      {
        Header: "天気",
        accessor: "weather",
      },
      {
        Header: "騎手",
        accessor: "jockey.jockey_name",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      <div className="horse-history-title">
        <h3>{horseDetail ? horseDetail.horse_name : ""} 出走履歴</h3>
      </div>
      <div className="horse-history-container">
        {loading ? (
          <Loading />
        ) : !horseDetail ? (
          <p>出走履歴なし</p>
        ) : (
          <table {...getTableProps()} className="horse-history-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default HorseHistory;
