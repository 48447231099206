import { Link, useLocation } from "react-router-dom";
import "./GlobalMenuButton.css";

const GlobalMenuButton = ({ link, name }) => {
  //URLを取得後、linkと比較して合っていればselectedのCSS適用
  const location = useLocation();
  let buttonStyle;
  if (location.pathname === link) {
    buttonStyle = "selected";
  } else if (location.pathname === "/race/horses" && link === "/races") {
    buttonStyle = "selected";
  } else if (
    location.pathname === "/race/horses_history" &&
    link === "/races_history"
  ) {
    buttonStyle = "selected";
  } else {
    buttonStyle = "unselected";
  }

  return (
    <Link to={link}>
      <button className={buttonStyle}>{name}</button>
    </Link>
  );
};
export default GlobalMenuButton;
