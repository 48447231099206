import axios from "axios";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import getTodayOrTomorrowDate from "../helpers/GetTodayOrTomorrowDate";
import Nend from "../helpers/Nend";
import pastClassName from "../helpers/PastClassName";
import "./Races-Header.css";
import "./Races.css";

const url = process.env.REACT_APP_BACKEND_ENDPOINT;
const username = process.env.REACT_APP_BACKEND_USERNAME;
const password = process.env.REACT_APP_BACKEND_PASSWORD;

function Races() {
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [date, setDate] = useState(schedule[0]);
  const [places, setPlaces] = useState([]);
  const [place, setPlace] = useState(places[0]);
  const [races, setRaces] = useState([]);

  const [selectedDate, setSelectedDate] = useState(schedule[0]);
  const [selectedPlace, setSelectedPlace] = useState(places[0]);

  // Horsesから渡されて上書きされるもの
  const location = useLocation();
  const updateDate = useRef(undefined);
  const updatePlace = useRef(undefined);
  useEffect(() => {
    if (location.state !== null) {
      if (location.state.updateDate !== undefined) {
        updateDate.current = location.state.updateDate;
      }
      if (location.state.updatePlace !== undefined) {
        updatePlace.current = location.state.updatePlace;
      }
    } else {
      updateDate.current = undefined;
      updatePlace.current = undefined;
    }
  }, []);
  useEffect(() => {
    if (updateDate.current !== undefined) {
      setDate(updateDate.current);
      setSelectedDate(updateDate.current);
    }
  }, [updateDate.current]);
  useEffect(() => {
    if (updatePlace.current !== undefined) {
      setPlace(updatePlace.current);
      setSelectedPlace(updatePlace.current);
    }
  }, [updatePlace.current]);

  const handleClickDate = (date) => {
    setDate(date);
  };
  const handleChangeSelectedDate = (date) => {
    setSelectedDate(date);
  };

  const handleClickPlace = (place) => {
    setPlace(place);
  };
  const handleChangeSelectedPlace = (place) => {
    setSelectedPlace(place);
  };

  useEffect(() => {
    async function getSchedule() {
      setLoading(true);
      try {
        await axios
          .get(`${url}/schedule`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            auth: {
              username: username,
              password: password,
            },
          })
          .then((res) => {
            const resSchedule = res.data.schedule;
            const tmp1 = resSchedule.map((tmp) => tmp.race_date);
            setSchedule([...schedule, ...tmp1]);

            // 当日または翌日がデフォルトで選択されているようにする
            const initSchedule = getTodayOrTomorrowDate(tmp1);
            setDate(initSchedule);
            setSelectedDate(initSchedule);
          });
      } catch (e) {
        // TODO: 開催日一覧が取得できなかったときの対応
      }
    }

    getSchedule();
  }, []); // 初回レンダリング時のみ実行

  useEffect(() => {
    async function getPlaces() {
      if (date === undefined) return; // dateが取得できる前ではリクエストしない
      setLoading(true);
      try {
        await axios
          .get(`${url}/places?race_date=${date}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            auth: {
              username: username,
              password: password,
            },
          })
          .then((res) => {
            const places = res.data.places;
            setPlaces(places);

            // 取得できたら先頭のplaceで更新
            const resPlace = res.data.places;
            setPlace(resPlace[0].name);
            setSelectedPlace(resPlace[0].name);
          });
      } catch (e) {
        console.log(e);
        setPlaces([{ name: "取得中..." }]);
      }
    }

    getPlaces();
  }, [date]); // 日付が変更されたときのみ実行

  useEffect(() => {
    async function getRaces() {
      // dateやplaceが取得できるまでは実行しない
      if (date === undefined || place === undefined) {
        return false;
      }
      setLoading(true);
      try {
        await axios
          .get(`${url}/races?race_date=${date}&place=${place}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            auth: {
              username: username,
              password: password,
            },
          })
          .then((res) => {
            const races = res.data.races;
            setLoading(false);
            setRaces(races);
          });
      } catch (e) {
        console.log(e);
        // setLoading(false);
        setRaces([{ race_name: "レース一覧取得失敗" }]);
      }
    }

    getRaces();
  }, [date + place]); // 日付もしくは競馬場が変更されたときのみ実行

  return (
    <div>
      <div className="body">
        <div className="content">
          <div className="radiobox">
            {schedule.map((date) => (
              <div className="date">
                <input
                  id={date}
                  className="radio_button_date"
                  type="radio"
                  value={date}
                  onChange={() => {
                    handleChangeSelectedDate(date);
                    handleClickDate(date);
                  }}
                  checked={date === selectedDate}
                />
                <label for={date}>{date.slice(5).replace("-", "/")}</label>
              </div>
            ))}
          </div>
          <div className="places">
            <div className="radiobox">
              {places.map((place) => (
                <div className="place">
                  <input
                    id={place.name}
                    className="radio_button_place"
                    type="radio"
                    value={place.name}
                    onChange={() => {
                      handleChangeSelectedPlace(place.name);
                      handleClickPlace(place.name);
                    }}
                    checked={place.name === selectedPlace}
                  />
                  <label for={place.name}>{place.name}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="nend-container">
            <Nend
              params={{
                media: 71818,
                site: 367030,
                spot: 1084545,
                type: 1,
                oriented: 1,
              }}
            />
          </div>

          {loading ? (
            <Loading />
          ) : (
            <div className="races">
              {races.map((race) => (
                <li className="race">
                  <Link
                    className={classNames(
                      "race-link",
                      pastClassName(date, race.start_datetime)
                    )}
                    to="/race/horses"
                    state={{
                      raceId: race.race_id,
                      dateList: schedule,
                      date: date,
                      places: places,
                      place: place,
                      raceCount: races.length,
                    }}
                  >
                    {race.ordinal}R
                  </Link>
                  <div className="race-container">
                    <div className="race-description">
                      <Link
                        className="race-link-sub"
                        to="/race/horses"
                        state={{
                          raceId: race.race_id,
                          dateList: schedule,
                          date: date,
                          places: places,
                          place: place,
                          raceCount: races.length,
                        }}
                      >
                        <p className="race-name">{race.race_name} </p>
                      </Link>
                      <p className="race-detail">
                        {race.start_datetime} {race.race_type}
                        {race.course_length}m {race.head_count}頭
                      </p>
                    </div>
                    {race.is_estimated ? (
                      <p className="existing-ai-index">AI</p>
                    ) : (
                      ""
                    )}
                    {race.is_result ? (
                      <p className="existing-result">着順</p>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Races;
