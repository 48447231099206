import axios from "axios";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import Loading from "../components/Loading";
import aiIndexClassName from "../helpers/AiIndexClassName";
import frameClassName from "../helpers/FrameClassName";
import indexClassName from "../helpers/IndexClassName";
import Nend from "../helpers/Nend";
import otherClassName from "../helpers/OtherClassName";
import pastClassName from "../helpers/PastClassName";
import popularityClassName from "../helpers/popularityClassName";
import selectedOrdinalClassName from "../helpers/SelectedOrdinalClassName";

const url = process.env.REACT_APP_BACKEND_ENDPOINT;
const username = process.env.REACT_APP_BACKEND_USERNAME;
const password = process.env.REACT_APP_BACKEND_PASSWORD;

function HistoryHorses() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dateList = location.state.dateList;
  const selectedDate = location.state.date;
  const places = location.state.places;
  const raceCount = location.state.raceCount;

  const [selectedPlace, setSelectedPlace] = useState(location.state.place);
  const [raceId, setRaceId] = useState(location.state.raceId);
  const [ordinal, setOrdinal] = useState("");

  const [raceName, setRaceName] = useState("");
  const [startDatetime, setStartDatetime] = useState("");
  const [raceType, setRaceType] = useState("");
  const [courseLength, setCourseLength] = useState("");
  const [existResult, setExistResult] = useState(false);
  const [existAiIndex, setExistAiIndex] = useState(false);
  const [selectedOrdinal, setSelectedOrdinal] = useState(ordinal);

  const [tableData, setTableData] = useState([]);

  const handleClickPlace = (place) => {
    setSelectedPlace(place);
  };
  const handleClickOrdinal = (ordinal) => {
    setOrdinal(ordinal);
    setSelectedOrdinal(ordinal);
  };

  useEffect(() => {
    async function getHorses() {
      setLoading(true);
      try {
        await axios
          .get(`${url}/race/horses?race_id=${raceId}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            auth: {
              username: username,
              password: password,
            },
          })
          .then((res) => {
            const response = res.data;
            setLoading(false);
            setOrdinal(response.ordinal);
            setSelectedOrdinal(response.ordinal);
            setRaceName(response.race_name);
            setStartDatetime(response.start_datetime);
            setRaceType(response.race_type);
            setCourseLength(response.course_length);
            setTableData(
              response.horses.map((horse) => ({
                order: horse.order,
                frame: horse.frame,
                horseInfo: horse.name + "(" + horse.sex + ")",
                jockeyInfo: horse.jockey_name,
                popularity: horse.popularity,
                odds: horse.odds.toFixed(1),
                aiIndex: parseFloat(horse.ai_index * 100).toFixed(1),
                wakuWinrate: parseFloat(
                  Math.round(horse.waku_winrate * 10) / 10
                ).toFixed(1),
                result: horse.result === 0 ? "-" : horse.result,
              }))
            );
            if (response.horses[0].result > 0) {
              setExistResult(true);
            }
            if (response.horses[0].ai_index > 0) {
              setExistAiIndex(true);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }

    getHorses();
  }, [raceId]); // raceIdが変更になったときのみ実行

  useEffect(() => {
    async function getRaceId() {
      if (ordinal === "") return; // レース番号が未定の状態ではリクエストしない
      try {
        await axios
          .get(
            `${url}/race?race_date=${selectedDate}&place=${selectedPlace}&ordinal=${ordinal}`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
              },
              auth: {
                username: username,
                password: password,
              },
            }
          )
          .then((res) => {
            const resRaceId = res.data.race_id;
            setRaceId(resRaceId);
          });
      } catch (e) {
        console.log(e);
      }
    }

    getRaceId();
  }, [selectedPlace, ordinal]); // 競馬場もしくはレース番号が変更されたときのみ実行

  const detailThStyle = {
    backgroundColor: "#999999",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  };
  const detailTdStyle = {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            着順
          </span>
        ),
        accessor: "result",
        sortBy: "number",
      },
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            馬番
          </span>
        ),
        accessor: "order",
        sortType: "number",
      },
      {
        Header: "枠\n複勝率",
        accessor: "wakuWinrate",
        disableSortBy: true,
      },
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            AI指数
          </span>
        ),
        accessor: "aiIndex",
        sortType: "number",
      },
      {
        Header: "馬名(性)",
        accessor: "horseInfo",
        disableSortBy: true,
      },
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            人気
          </span>
        ),
        accessor: "popularity",
        sortType: "number",
      },
      {
        Header: "オッズ",
        accessor: "odds",
        disableSortBy: true,
      },
      {
        Header: "騎手",
        accessor: "jockeyInfo",
        disableSortBy: true,
      },
    ],
    []
  );

  let sortByOptions = [];
  if (existResult) {
    sortByOptions.push({ id: "result", asc: true });
  } else if (existAiIndex) {
    sortByOptions.push({ id: "aiIndex", desc: true });
  } else {
    sortByOptions.push({ id: "order", asc: true });
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: tableData,
        initialState: {
          sortBy: sortByOptions,
        },
      },
      useSortBy
    );

  return (
    <div>
      <div className="body">
        <div className="content">
          <div className="dates">
            <div className="radiobox">
              {dateList.map((dt) => (
                <div className="date">
                  <input
                    id={dt}
                    className="radio_button_date"
                    type="radio"
                    value={dt}
                    checked={dt === selectedDate}
                  />
                  <label for={dt}>
                    <Link id="races" to="/races" state={{ updateDate: dt }}>
                      {dt.slice(5).replace("-", "/")}
                    </Link>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="places">
            <div className="radiobox">
              {places.map((pl) => (
                <div className="place">
                  <input
                    id={pl.name}
                    className="radio_button_place"
                    type="radio"
                    value={pl.name}
                    checked={pl.name === selectedPlace}
                  />
                  <label
                    for={pl.name}
                    onClick={() => handleClickPlace(pl.name)}
                    style={{ cursor: "pointer" }}
                  >
                    {pl.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="other-races-container">
            <div className="radiobox">
              {[...Array(raceCount)].map((_, count) => (
                <div
                  className={classNames(
                    "race-link-small",
                    selectedOrdinalClassName(count + 1, selectedOrdinal)
                  )}
                  onClick={() => handleClickOrdinal(count + 1)}
                >
                  {count + 1}R
                </div>
              ))}
            </div>
          </div>
          <div className="nend-container">
            <Nend
              params={{
                media: 71818,
                site: 367030,
                spot: 1084545,
                type: 1,
                oriented: 1,
              }}
            />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="horse-race-container">
                <div
                  className={classNames(
                    "race-link",
                    pastClassName(selectedDate, startDatetime)
                  )}
                >
                  {ordinal}R
                </div>
                <div className="race-description">
                  <p className="horse-race-name">{raceName}</p>
                  <p className="horse-race-detail">
                    {startDatetime} {raceType}
                    {courseLength}m
                  </p>
                </div>
              </div>
              <div className={classNames("horses", "history")}>
                <table {...getTableProps()} border="0">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={detailThStyle}
                          >
                            {column.render("Header")}
                            {column.canSort &&
                              (() => {
                                return (
                                  <div>
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? "↓"
                                        : "↑"
                                      : ""}
                                  </div>
                                );
                              })()}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={detailTdStyle}
                                className={classNames(
                                  aiIndexClassName(
                                    cell.column.Header.props,
                                    cell.value
                                  ),
                                  popularityClassName(
                                    cell.column.Header.props,
                                    cell.value
                                  ),
                                  frameClassName(
                                    cell.column.Header.props,
                                    row.original.frame
                                  ),
                                  indexClassName(
                                    cell.column.Header,
                                    cell.value
                                  ),
                                  otherClassName(cell.column.Header, cell.value)
                                )}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryHorses;
