import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Footer from "./components/Footer";
import GlobalMenu from "./components/GlobalMenu";
import Header from "./components/Header";
import Nend from "./helpers/Nend";
import UsePageTracking from "./helpers/UseTracking";
import Contact from "./pages/Contact";
import HajimeColumun from "./pages/HajimeColumun";
import HistoryHorses from "./pages/HistoryHorses";
import HistoryRaces from "./pages/HistoryRaces";
import Home from "./pages/Home";
import HorseHistory from "./pages/HorseHistory";
import Horses from "./pages/Horses";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Races from "./pages/Races";
import TermsOfService from "./pages/TermsOfService";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <div className="App">
          <Header />
          <GlobalMenu />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/races" element={<Races />} />
            <Route path="/race/horses" element={<Horses />} />
            <Route path="/races_history" element={<HistoryRaces />} />
            <Route path="/race/horses_history" element={<HistoryHorses />} />
            <Route
              path="/horses/:horse_id/history"
              element={<HorseHistory />}
            />
            <Route path="/hajime" element={<HajimeColumun />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Nend
            params={{
              media: 71818,
              site: 367030,
              spot: 1084427,
              type: 1,
              oriented: 1,
            }}
          />
          <Footer />
        </div>
        <UsePageTracking />
      </BrowserRouter>
    </>
  );
};

export default App;
