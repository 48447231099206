import React from "react";
import { FaTwitterSquare } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="icons-wrapper">
        <IconContext.Provider value={{ className: "twitter-icon" }}>
          <a
            href="https://twitter.com/inanaki_ai?ref_src=twsrc%5Etfw"
            class="twitter-follow-button"
            data-show-count="false"
          >
            <FaTwitterSquare />
          </a>
        </IconContext.Provider>
      </div>
      <ul className="footer-ul">
        {/* <li className="footer-li">サイトマップ</li>*/}
        <li className="footer-li">
          <Link to="/privacy-policy">プライバシーポリシー</Link>
        </li>
        <li className="footer-li">
          <Link to="/terms-of-service">サイト利用規約</Link>
        </li>
        <li className="footer-li">
          <Link to="/contact">お問い合わせ</Link>
        </li>
      </ul>
      <p className="copyright">© 2023 Hajime,Shingo,Naohiro</p>
    </div>
  );
};

export default Footer;
