import React from "react";
import "./GlobalMenu.css";
import GlobalMenuButton from "./GlobalMenuButton";

const GlobalMenu = () => {
  return (
    <div className="global-menu">
      <GlobalMenuButton name="Home" link="/" />
      <GlobalMenuButton name="レース予想" link="/races" />
      <GlobalMenuButton name="レース履歴" link="/races_history" />
      {/*コンテンツ未実装のため表示させない*/}
      {/* <GlobalMenuButton name="コース情報" /> */}
      {/* <GlobalMenuButton name="はじめのコラム" link="/hajime" /> */}
    </div>
  );
};
export default GlobalMenu;
