function getTodayOrTomorrowDate(datesArray) {
  // 与えられた日付配列内に今日もしくは明日の日付を返却
  // もしない場合は最後の日付を返却
  const today = new Date().getDate();
  for (let i = 0; i < datesArray.length; i++) {
    const date = new Date(datesArray[i]).getDate();
    if (date === today) {
      return datesArray[i];
    } else if (date === today + 1) {
      return datesArray[i];
    }
  }
  return datesArray.slice(-1)[0];
}

export default getTodayOrTomorrowDate;
