function aiIndexClassName(propsHeader, value) {
  if (propsHeader === undefined) return null;
  if (propsHeader.children !== "AI指数") return null;
  if (15 <= value && value < 20) {
    return "ai-yellow";
  } else if (20 <= value && value < 30) {
    return "ai-yellow-orange";
  } else if (30 <= value && value < 40) {
    return "ai-orange";
  } else if (40 <= value) {
    return "ai-red-orange";
  } else {
    return null;
  }
}

export default aiIndexClassName;
