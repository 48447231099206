function pastClassName(raceDate, raceStartTime) {
  if (raceDate === undefined || raceStartTime === undefined) {
    return null;
  }
  const raceDateTime = raceDate + " " + raceStartTime;
  const now = new Date();
  const year = now.getFullYear().toString().padStart(4, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hour = now.getHours().toString().padStart(2, "0");
  const minute = now.getMinutes().toString().padStart(2, "0");
  const nowDateTime =
    year + "-" + month + "-" + day + " " + hour + ":" + minute;
  if (raceDateTime < nowDateTime) {
    return "past-race";
  } else {
    return null;
  }
}

export default pastClassName;
