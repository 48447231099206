function selectedOrdinalClassName(ordinal, selectedOrdinal) {
  if (ordinal === undefined || selectedOrdinal === undefined) return null;
  if (ordinal === selectedOrdinal) {
    return "race-link-small-selected";
  } else {
    return null;
  }
}

export default selectedOrdinalClassName;
