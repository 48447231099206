import "./TermsOfService.css";

function TermsOfService() {
  return (
    <div className="terms-of-service-container">
      <h1 className="headline">当サイトのご利用規約</h1>
      <h2 className="subheadline">第１条（適用）</h2>
      <p className="paragraph">
        1.本規約は，ユーザーと本サイト運営者との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        <br />
        2.本サイト運営者は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
        <br />
        3.本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
        <br />
      </p>
      <h2 className="subheadline">第２条（禁止事項）</h2>
      <p className="paragraph">
        1.ユーザーは、本サイト及び本サービスの利用に際して、以下各号のいずれかに該当する行為を行ってはなりません。
        <br />
        ①ハッキング行為、ウィルス送信、スパムメール送信等
        <br />
        ②本サイト上のプログラム、データベース等を改造又は改変
        <br />
        ③本サービスのコンテンツを不正の目的で利用する行為
        <br />
        ④第三者に利用させる行為、第三者のために利用する行為
        <br />
        ⑤法令に違反する行為又はその恐れのある行為
        <br />
        ⑥公序良俗に反する行為又はその恐れのある行為
        <br />
        ⑦情報送信時の虚偽の申告
        <br />
        ⑧当サイト又は第三者に損害を与える行為
        <br />
        ⑨当サイト又は第三者を誹謗中傷する行為
        <br />
        ⑩本サイト及び本サービスの運営を妨害する行為
        <br />
        2.本条及び本サイト内の各規約、各ガイドライン並びに法令の定めに違反した場合、ユーザーは、自身の違反行為によって生じる当サイト運営者又は第三者の損害をすべて自己の責任及び費用において賠償しなければなりません。
      </p>
      <h2 className="subheadline">第３条（自己責任原則、免責事項）</h2>
      <p className="paragraph">
        1.本サイトにおいて提供するすべての情報について、常に正確及び最新であるように努めるものとしますが、情報の正確性・最新性・合法性及び真実性等について、一切保証しないものとします。
      </p>
      <h2 className="subheadline">第4条（合意管轄、準拠法）</h2>
      <p className="paragraph">
        1.本規約は、日本法に準拠し、日本法に従って解釈されるものとします。
      </p>
      <h2 className="subheadline">第5条（本規約の変更）</h2>
      <p className="paragraph">
        1.当サイト運営者は、ユーザーに対して、事前に何らの通知を行うことなく、本規約を変更することができるものとします。
        <br />
        2.本規約を変更する場合、当サイトは変更後の規約を本サイト上に掲載することをもって、本規約の変更を通知します。
        <br />
        3.ユーザーは、本サイト又は本サービスを利用するごとに本規約を確認するものとします。当サイト運営者は、本規約の変更後、ユーザーが本サイト又は本サービスを利用したことをもって、当該ユーザーが本規約の変更を確認し、同意したものとみなします。
      </p>
    </div>
  );
}

export default TermsOfService;
