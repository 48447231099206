import axios from "axios";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSortBy, useTable } from "react-table";
import Loading from "../components/Loading";
import aiIndexClassName from "../helpers/AiIndexClassName";
import frameClassName from "../helpers/FrameClassName";
import indexClassName from "../helpers/IndexClassName";
import Nend from "../helpers/Nend";
import otherClassName from "../helpers/OtherClassName";
import pastClassName from "../helpers/PastClassName";
import selectedOrdinalClassName from "../helpers/SelectedOrdinalClassName";
import popularityClassName from "../helpers/popularityClassName";
import "./Horses.css";
import "./Races-Header.css";

const url = process.env.REACT_APP_BACKEND_ENDPOINT;
const username = process.env.REACT_APP_BACKEND_USERNAME;
const password = process.env.REACT_APP_BACKEND_PASSWORD;

function Horses() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dateList = location.state.dateList;
  const date = location.state.date;
  const places = location.state.places;
  const raceCount = location.state.raceCount;

  const [place, setPlace] = useState(location.state.place);
  const [raceId, setRaceId] = useState(location.state.raceId);
  const [ordinal, setOrdinal] = useState("");

  const [raceName, setRaceName] = useState("");
  const [startDatetime, setStartDatetime] = useState("");
  const [raceType, setRaceType] = useState("");
  const [courseLength, setCourseLength] = useState("");
  const [existResult, setExistResult] = useState(false);
  const [existAiIndex, setExistAiIndex] = useState(false);
  const [existWeight, setExistWeight] = useState(false);
  const [selectedOrdinal, setSelectedOrdinal] = useState(ordinal);

  const [tableData, setTableData] = useState([]);

  const handleClickPlace = (place) => {
    setPlace(place);
  };
  const handleClickOrdinal = (ordinal) => {
    setOrdinal(ordinal);
    setSelectedOrdinal(ordinal);
  };

  useEffect(() => {
    async function getHorses() {
      setLoading(true);
      try {
        await axios
          .get(`${url}/race/horses?race_id=${raceId}`, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Access-Control-Allow-Origin": "*",
            },
            auth: {
              username: username,
              password: password,
            },
          })
          .then((res) => {
            const response = res.data;
            setLoading(false);
            setOrdinal(response.ordinal);
            setSelectedOrdinal(response.ordinal);
            setRaceName(response.race_name);
            setStartDatetime(response.start_datetime);
            setRaceType(response.race_type);
            setCourseLength(response.course_length);

            // Check if weights are the same
            const firstHorseWeight = response.horses[0].weight;
            const allWeightsAreEqual = response.horses.every(
              (horse) => horse.weight === firstHorseWeight
            );
            if (allWeightsAreEqual && firstHorseWeight !== 0) {
              setExistWeight(true);
            } else {
              setExistWeight(false);
            }

            setTableData(
              response.horses.map((horse) => ({
                order: horse.order,
                frame: horse.frame,
                horse_id: horse.horse_id,
                horseInfo:
                  horse.name +
                  "\n" +
                  horse.sex +
                  horse.age +
                  "・" +
                  horse.weight +
                  "kg(" +
                  (horse.weight_change > 0 ? "+" : "") +
                  horse.weight_change +
                  ")",
                jockeyInfo:
                  horse.jockey_name +
                  "\n" +
                  parseFloat(horse.handicap_weight).toFixed(1),
                jpWinrate: parseFloat(horse.jp_winrate).toFixed(1),
                popularity: horse.popularity,
                odds: horse.odds.toFixed(1),
                aiIndex: parseFloat(horse.ai_index * 100).toFixed(1),
                wakuWinrate: parseFloat(
                  Math.round(horse.waku_winrate * 10) / 10
                ).toFixed(1),
                styleWinrate: parseFloat(
                  Math.round(horse.style_winrate * 10) / 10
                ).toFixed(1),
                style: horse.style,
                interval: parseFloat(
                  Math.round(horse.interval * 10) / 10
                ).toFixed(1),
                runtime3R: parseFloat(
                  Math.round(horse.runtime_3R * 10) / 10
                ).toFixed(1),
                spurt3R: parseFloat(
                  Math.round(horse.spurt_3R * 10) / 10
                ).toFixed(1),
                rush3R: parseFloat(Math.round(horse.rush_3R * 10) / 10).toFixed(
                  1
                ),
                diff3R: parseFloat(Math.round(horse.diff_3R * 10) / 10).toFixed(
                  1
                ),
                firstCorner3R: parseFloat(
                  Math.round(horse.first_corner_3R * 10) / 10
                ).toFixed(1),
                result: horse.result === 0 ? "99" : horse.result,
              }))
            );
            if (response.horses[0].result > 0) {
              setExistResult(true);
            }
            if (response.horses[0].ai_index > 0) {
              setExistAiIndex(true);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }

    getHorses();
  }, [raceId]); // raceIdが変更になったときのみ実行

  useEffect(() => {
    async function getRaceId() {
      if (ordinal === "") return; // レース番号が未定の状態ではリクエストしない
      try {
        await axios
          .get(
            `${url}/race?race_date=${date}&place=${place}&ordinal=${ordinal}`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
              },
              auth: {
                username: username,
                password: password,
              },
            }
          )
          .then((res) => {
            const resRaceId = res.data.race_id;
            setRaceId(resRaceId);
          });
      } catch (e) {
        console.log(e);
      }
    }

    getRaceId();
  }, [place, ordinal]); // 競馬場もしくはレース番号が変更されたときのみ実行

  const detailThStyle = {
    backgroundColor: "#999999",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  };
  const detailTdStyle = {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            着順
          </span>
        ),
        accessor: "result",
        sortBy: "number",
      },
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            馬番
          </span>
        ),
        accessor: "order",
        sortType: "number",
      },
      {
        Header: "枠\n複勝率",
        accessor: "wakuWinrate",
        disableSortBy: true,
      },
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            AI指数
          </span>
        ),
        accessor: "aiIndex",
        sortType: "number",
      },
      {
        Header: "馬名\n性齢・体重",
        accessor: "horseInfo",
        disableSortBy: true,
        Cell: ({ row }) => (
          <a
            className="no-text-decoration"
            href={`/horses/${row.original.horse_id}/history`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.values.horseInfo}
          </a>
        ),
      },
      {
        Header: (
          <span style={{ color: "crimson", cursor: "pointer" }} role="button">
            人気
          </span>
        ),
        accessor: "popularity",
        sortType: "number",
      },
      {
        Header: "オッズ",
        accessor: "odds",
        disableSortBy: true,
      },
      {
        Header: "前走\n経過月",
        accessor: "interval",
        disableSortBy: true,
      },
      {
        Header: "脚質",
        accessor: "style",
        disableSortBy: true,
      },
      {
        Header: "1st角\n順位",
        accessor: "firstCorner3R",
        disableSortBy: true,
      },
      {
        Header: "脚質\n勝率",
        accessor: "styleWinrate",
        disableSortBy: true,
      },
      {
        Header: "走力\n指数",
        accessor: "runtime3R",
        disableSortBy: true,
      },
      {
        Header: "上がり3F\n指数",
        accessor: "spurt3R",
        disableSortBy: true,
      },
      {
        Header: "追込\n指数",
        accessor: "rush3R",
        disableSortBy: true,
      },
      {
        Header: "着差\n指数",
        accessor: "diff3R",
        disableSortBy: true,
      },
      {
        Header: "騎手\n斤量",
        accessor: "jockeyInfo",
        disableSortBy: true,
      },
      {
        Header: "騎手\n複勝率",
        accessor: "jpWinrate",
        disableSortBy: true,
      },
    ],
    []
  );

  let sortByOptions = [];
  if (existResult) {
    sortByOptions.push({ id: "result", asc: true });
  } else if (existAiIndex) {
    sortByOptions.push({ id: "aiIndex", desc: true });
  } else {
    sortByOptions.push({ id: "order", asc: true });
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: tableData,
        initialState: {
          sortBy: sortByOptions,
        },
      },
      useSortBy
    );

  return (
    <div>
      <div className="body">
        <div className="content">
          <div className="dates">
            <div className="radiobox">
              {dateList.map((dt) => (
                <div className="date">
                  <input
                    id={dt}
                    className="radio_button_date"
                    type="radio"
                    value={dt}
                    checked={dt === date}
                  />
                  <label for={dt}>
                    <Link id="races" to="/races" state={{ updateDate: dt }}>
                      {dt.slice(5).replace("-", "/")}
                    </Link>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="places">
            <div className="radiobox">
              {places.map((pl) => (
                <div className="place">
                  <input
                    id={pl.name}
                    className="radio_button_place"
                    type="radio"
                    value={pl.name}
                    checked={pl.name === place}
                  />
                  <label
                    for={pl.name}
                    onClick={() => handleClickPlace(pl.name)}
                    style={{ cursor: "pointer" }}
                  >
                    {pl.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="other-races-container">
            <div className="radiobox">
              {[...Array(raceCount)].map((_, count) => (
                <div
                  className={classNames(
                    "race-link-small",
                    selectedOrdinalClassName(count + 1, selectedOrdinal)
                  )}
                  onClick={() => handleClickOrdinal(count + 1)}
                >
                  {count + 1}R
                </div>
              ))}
            </div>
          </div>
          <div className="nend-container">
            <Nend
              params={{
                media: 71818,
                site: 367030,
                spot: 1084545,
                type: 1,
                oriented: 1,
              }}
            />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="horse-race-container">
                <div
                  className={classNames(
                    "race-link",
                    pastClassName(date, startDatetime)
                  )}
                >
                  {ordinal}R
                </div>
                <div className="race-description">
                  <p className="horse-race-name">{raceName}</p>
                  <p className="horse-race-detail">
                    {startDatetime} {raceType}
                    {courseLength}m
                  </p>
                </div>
                <div className="notice-no-weight">
                  {existWeight ? (
                    <>
                      <p style={{ margin: 0 }}>
                        ※馬体重測定前なのでAI指数は暫定値
                      </p>
                      <p style={{ margin: 0, marginLeft: "0.8em" }}>
                        馬体重が確定次第AI指数も更新
                      </p>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="horses">
                <table {...getTableProps()} border="0">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={detailThStyle}
                          >
                            {column.render("Header")}
                            {column.canSort &&
                              (() => {
                                return (
                                  <div>
                                    {column.isSorted
                                      ? column.isSortedDesc
                                        ? "↓"
                                        : "↑"
                                      : ""}
                                  </div>
                                );
                              })()}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={detailTdStyle}
                                className={classNames(
                                  aiIndexClassName(
                                    cell.column.Header.props,
                                    cell.value
                                  ),
                                  popularityClassName(
                                    cell.column.Header.props,
                                    cell.value
                                  ),
                                  frameClassName(
                                    cell.column.Header.props,
                                    row.original.frame
                                  ),
                                  indexClassName(
                                    cell.column.Header,
                                    cell.value
                                  ),
                                  otherClassName(cell.column.Header, cell.value)
                                )}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <p className="column-description">
                {/* ※騎手複勝率: 直近60レースの騎手の複勝率
                <br /> */}
                ※1st角順位: 直近3レースの最初のコーナー進入順位の平均値
                <br />
                ※走力指数: 直近3レースの走破タイム平均値を0~100で正規化した値
                <br />
                ※上がり3F指数:
                直近3レースの上がり3Fの平均値を0~100で正規化した値
                <br />
                ※追込指数:
                直近3レースの最終コーナーからゴールするまでに追い上げて縮めた距離の平均値を0~100で正規化した値
                <br />
                ※着差指数: 直近3レースの着差平均値を0~100で正規化した値
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Horses;
