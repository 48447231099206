import React from "react";
import AiIcon from "./AiIcon.png";
import "./Usecase.css";
import UseCase from "./UseCase.png";

const Usecase = () => {
  return (
    <div className="update-schedule-container">
      <h2 className="head-line">■情報の更新タイミング</h2>
      <table className="update-table" border={1}>
        <tr className="table-row">
          <td>曜日</td>
          <td>更新情報</td>
        </tr>
        <tr className="table-row">
          <td className="table-data-left">金</td>
          <td className="table-data">出馬表</td>
        </tr>
        <tr className="table-row">
          <td className="table-data-left">土日</td>
          <td className="table-data">
            各レース開始1時間前（目安）に
            <span className="race-ai">レース予想AI指数</span>
            <br />
            AI指数ありのレースには
            <img className="ai-icon" src={AiIcon} alt="AI指数有" />
            マークを表示
            <br />
            <span className="annotation">
              ※出走実績の無い新馬レースでは、
              <br />
              レース予想AI指数を表示いたしません。
            </span>
          </td>
        </tr>
      </table>
      <h2 className="head-line">■馬券購入の流れ</h2>
      <img className="use-case" src={UseCase} alt="ユースケース図" />
    </div>
  );
};

export default Usecase;
