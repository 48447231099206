function oddsClassName(value) {
  console.log(value);
  if (value < 5) {
    return "odds-blue";
  } else {
    return null;
  }
}

function IntervalClassName(value) {
  if (value >= 6.0) {
    return "interval-purple";
  } else {
    return null;
  }
}

function wakuWinrateClassName(value) {
  if (21 <= value && value < 22) {
    return "waku-winrate-21-22";
  } else if (22 <= value && value < 23) {
    return "waku-winrate-22-23";
  } else if (23 <= value && value < 24) {
    return "waku-winrate-23-24";
  } else if (24 <= value) {
    return "waku-winrate-24-";
  } else {
    return null;
  }
}

function styleWinrateClassName(value) {
  if (17 <= value && value < 20) {
    return "style-winrate-17-20";
  } else if (20 <= value && value < 22.5) {
    return "style-winrate-20-225";
  } else if (22.5 <= value && value < 25) {
    return "style-winrate-225-25";
  } else if (25 <= value) {
    return "style-winrate-25-";
  } else {
    return null;
  }
}

function jockeyWinrateClassName(value) {
  if (20 <= value && value < 30) {
    return "jockey-winrate-20-30";
  } else if (30 <= value && value < 40) {
    return "jockey-winrate-30-40";
  } else if (40 <= value && value < 50) {
    return "jockey-winrate-40-50";
  } else if (50 <= value) {
    return "jockey-winrate-50-";
  } else {
    return null;
  }
}

function styleClassName(value) {
  switch (value) {
    case "逃げ":
      return "style-nige";
    case "先行":
      return "style-senko";
    case "差し":
      return "style-sashi";
    case "追込":
      return "style-oikomi";
    default:
      return null;
  }
}

function otherClassName(headerName, value) {
  if (headerName === undefined || typeof headerName !== "string") return null;
  switch (headerName) {
    case "オッズ":
      return oddsClassName(value);
    case "前走\n経過月":
      return IntervalClassName(value);
    case "枠\n複勝率":
      return wakuWinrateClassName(value);
    case "脚質\n勝率":
      return styleWinrateClassName(value);
    case "騎手\n複勝率":
      return jockeyWinrateClassName(value);
    case "脚質":
      return styleClassName(value);
    default:
      return null;
  }
}

export default otherClassName;
