import axios from "axios";
import { useForm } from "react-hook-form";
import "./Contact.css";

const mailUrl = process.env.REACT_APP_MAIL_ENDPOINT;

function Contact() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await axios
        .post(mailUrl, {
          subject: data.subject,
          email: data.email,
          body: data.opinions,
        })
        .then((res) => {
          alert("送信しました。お問い合わせありがとうございました。");
        });
    } catch (e) {
      console.log(e);
      alert("送信に失敗しました。");
    }
    reset();
  };
  return (
    <div className="contact-container">
      <h1 className="headline">お問い合わせ</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="sub-head-line">
          <label className="form-subject" htmlFor="subject">
            題名
          </label>
          <br />
          <input
            className="subject-text-box"
            type="text"
            id="subject"
            {...register("subject", {
              required: { value: true, message: "入力が必要な項目です。" },
            })}
          />
          {errors.subject && (
            <div className="error-message">{errors.subject.message}</div>
          )}
        </div>
        <div className="sub-head-line">
          <label className="form-name" htmlFor="email">
            Eメールアドレス
          </label>
          <br />
          <input
            className="email-text-box"
            type="email"
            id="email"
            {...register("email", {
              required: { value: true, message: "入力が必要な項目です。" },
            })}
          />
          {errors.email && (
            <div className="error-message">{errors.email.message}</div>
          )}
        </div>
        <div className="sub-head-line">
          <label className="form-name" htmlFor="opinions">
            ご意見・ご要望など(機能追加要望なども歓迎！)
          </label>
          <br />
          <textarea
            className="opinions-text-box"
            type="text"
            id="opinions"
            {...register("opinions", {
              required: { value: true, message: "入力が必要な項目です。" },
            })}
          />
          {errors.opinions && (
            <div className="error-message">{errors.opinions.message}</div>
          )}
        </div>
        <div className="button-container">
          <button className="submit-button" type="submit">
            送信
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
