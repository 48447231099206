import { CircularProgress } from "@material-ui/core";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <CircularProgress color="inherit" />
    </div>
  );
};

export default Loading;
