import React from "react";
import "./InfoWindow.css";

const InfoWindow = () => {
  return (
    <div className="info-window-container">
      <h1 className="headline">inanAkI 競馬AIについて</h1>
      <h2 className="overview">■サイトの概要</h2>
      <p className="explanation">
        {" "}
        当サイトは、JRA主催の中央競馬について
        <span className="red-font">
          独自開発したAIを用いて予想した単勝確率（AI指数）
        </span>
        をはじめとした独自情報を掲載しています。
        <br />
      </p>
      <h2 className="overview">■AI指数について</h2>
      <p className="explanation">
        ・AI指数とは、AIモデルを用いて計算した予想単勝確率です。
        <br />
        ・AIモデルには独自に処理した約1000個のパラメータを用いており、完全オリジナルなモデルです。
        <br />
        ・計算パラメーターから人の予想に関する変数（オッズ）を除外することで、客観情報のみでAI指数を算出しています。
        <br />
        ・AIモデルは現在も精度向上の為の開発を続けており、当サイトでは常に最新のモデルを用いて計算したAI指数を掲載します。
        <br />
      </p>
    </div>
  );
};

export default InfoWindow;
