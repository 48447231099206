import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1 className="headline">プライバシーポリシー</h1>
      <h2 className="cross-head">1.基本方針</h2>
      <p className="paragraph">
        当サイトは、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、当サイトで取扱う個人情報の取得、利用、管理を適正に行います。当サイトで収集した情報は、利用目的の範囲内で適切に取り扱います。
      </p>
      <h2 className="cross-head">2.適用範囲</h2>
      <p className="paragraph">
        本プライバシーポリシーは、当サイトにおいてのみ適用されます。
      </p>
      <h2 className="cross-head">3.個人情報の取得と利用目的</h2>
      <p className="paragraph">
        当サイトで取得する訪問者の個人情報と利用目的、保存期間等は下記の通りです。
      </p>
      <h3 className="sub-headline">3-1.お問い合せされた個人情報を取得します</h3>
      <p className="paragraph">
        当サイトではお問い合わせフォームを設けています。
        <br />
        訪問者がそのお問い合わせフォームから問い合わせをされた際に入力された、以下の個人情報を取得します。
        <br />
        ・お問い合わせフォームに入力された題名
        <br />
        ・お問い合わせフォームに入力されたメールアドレス
        <br />
        ・お問い合わせフォームに入力されたお問合せ内容
        <br />
      </p>
      <h4 className="small-subheadline">3-1-1.利用目的について</h4>
      <p className="paragraph">
        お問い合わせ対応をするためと、訪問者の管理のためです。訪問者からのお問い合わせ情報を保存しておくことによって、同じ訪問者が別のお問い合わせをした際に、過去の問い合わせ内容を踏まえた対応をすることが出来、より的確な対応をすることが出来ます。
        <br />
        また、当サイト内で「このようなお問合せがありました」と紹介させていただく場合もあります。
      </p>
      {/* <h4 className="small-subheadline">3-1-2.保存期間について</h4>
      <p className="paragraph">
        お問い合わせフォームに入力された個人情報は、3年間保存します。
      </p> */}
      <h4 className="small-subheadline">3-1-2.個人情報取得の同意について</h4>
      <p className="paragraph">
        当サイトでは、お問い合わせフォームからお問い合わせをする前に、当プライバシーポリシーをご一読いただくよう案内しています。
        <br />
        お問い合わせをされた時点で、その訪問者は当プライバシーポリシーに同意されたとみなします。
      </p>
      <h3 className="sub-headline">3-2.Cookieによる個人情報の取得</h3>
      <p className="paragraph">
        当サイトは、訪問者のコンピュータにCookieを送信することがあります。
        <br />
        Cookie（クッキー）とは、ウェブサイトを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、訪問者のコンピュータにファイルとして保存しておく仕組みです。
      </p>
      <h4 className="small-subheadline">3-2-1.利用目的について</h4>
      <p className="paragraph">
        訪問者の当サイト閲覧時の利便性を高めるためです。
        <br />
        たとえば、次回同じページにアクセスするとCookieの情報を使って、ページの運営者は訪問者ごとに表示を変えることができます。
        <br />
        訪問者がブラウザの設定でCookieの送受信を許可している場合、ウェブサイトは、訪問者のブラウザからCookieキーを取得できます。
        <br />
        なお、訪問者のブラウザはプライバシー保護のため、そのウェブサイトのサーバーが送受信したCookieのみを送信します。
      </p>
      <h4 className="small-subheadline">
        3-2-2.第三者によるCookie情報の取得について
      </h4>
      <p className="paragraph">
        当サイトでは、グーグルやヤフーなどをはじめとする第三者から配信される広告が掲載される場合があります。
        <br />
        当該第三者は訪問者のCookie情報等を使用して、訪問者がそのウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて、訪問者に適切な広告を配信できます。
        <br />
        当該第三者によって取得されたCookie情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。
      </p>
      <h4 className="small-subheadline">
        3-2-3.第三者へのCookie情報等の広告配信の利用停止について
      </h4>
      <p className="paragraph">
        訪問者は、当該第三者のウェブサイト内に設けられたオプトアウト（個人情報を第三者に提供することを停止する）ページにアクセスして、当該第三者によるCookie情報等の広告配信への利用を停止することができます。
      </p>
      <h4 className="small-subheadline">
        3-2-4.Cookie情報の送受信の許可・拒否について
      </h4>
      <p className="paragraph">
        訪問者は、Cookieの送受信に関する設定を「すべてのCookieを許可する」、「すべてのCookieを拒否する」、「Cookieを受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザにより異なります。Cookieに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。
        <br />
        すべてのCookieを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合があります。
      </p>
      <h2 className="cross-head">4.個人情報の管理</h2>
      <p className="paragraph">
        当サイトは、訪問者からご提供いただいた情報の管理について、以下を徹底します。
      </p>
      <h3 className="sub-headline">4-1.情報の正確性の確保</h3>
      <p className="paragraph">
        訪問者からご提供いただいた情報については、常に正確かつ最新の情報となるよう努めます。
      </p>
      <h3 className="sub-headline">4-2.安全管理措置</h3>
      <p className="paragraph">
        当サイトは、個人情報の漏えいや滅失又は棄損を防止するために、適切なセキリュティ対策を実施して個人情報を保護します。
      </p>
      <h3 className="sub-headline">4-3.個人情報の廃棄</h3>
      <p className="paragraph">
        個人情報が不要となった場合には、すみやかに廃棄します。
      </p>
      <h3 className="sub-headline">
        4-4.個人情報の開示、訂正、追加、削除、利用停止
      </h3>
      <p className="paragraph">
        訪問者ご本人からの個人情報の開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
      </p>
      <h2 className="cross-head">5.個人情報の第三者への提供について</h2>
      <p className="paragraph">
        当サイトは、訪問者からご提供いただいた個人情報を、訪問者本人の同意を得ることなく第三者に提供することはありません。また、今後第三者提供を行うことになった場合には、提供する情報と提供目的などを提示し、訪問者から同意を得た場合のみ第三者提供を行います。
      </p>
      <h2 className="cross-head">6.未成年の個人情報について</h2>
      <p className="paragraph">
        未成年者が当サイトにコメントをしたり、お問い合わせフォームから問い合わせをされたりする場合は必ず親権者の同意を得るものとし、コメントやお問い合わせをされた時点で、当プライバシーポリシーに対して親権者の同意があるものとみなします。
      </p>
      <h2 className="cross-head">8.アクセス解析ツールについて</h2>
      <p className="paragraph">
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        <br />
        このGoogleアナリティクスはアクセス情報の収集のためにCookieを使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではありません。
        <br />
        GoogleアナリティクスのCookieは、26ヶ月間保持されます。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </p>
      <h2 className="cross-head">9.第三者配信の広告サービスについて</h2>
      <p className="paragraph">
        当サイトは、第三者配信の広告サービス
        {/*「Google
        Adsense（グーグルアドセンス）」*/}
        を利用しています。
        <br />
        {/*Googleなどの*/}
        第三者広告配信事業者は、訪問者の興味に応じた広告を表示するために、Cookie（当サイトの訪問者が当サイトや他のサイトにアクセスした際の情報など）を使用することがあります。
        <br />
        第三者配信による広告掲載を無効にしていない場合は、第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されることがあります。
        <br />
        Googleによって広告の第三者配信が認められている広告配信事業者の詳細は、こちらで確認できます。
      </p>
      <h2 className="cross-head">11.プライバシーポリシーの変更について</h2>
      <p className="paragraph">
        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。
        <br />
        <br />
        令和5年2月14日 策定
      </p>
    </div>
  );
}

export default PrivacyPolicy;
